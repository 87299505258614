import React from 'react';
import styled, { css } from 'styled-components';
import Parser from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import sanitizeHtml from 'sanitize-html';
import { media } from 'ui';

const ALLOWED_TAGS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote',
  'p',
  'a',
  'ul',
  'ol',
  'nl',
  'li',
  'b',
  'i',
  'strong',
  'em',
  'strike',
  'sup',
  'code',
  'hr',
  'br',
  'div',
  'table',
  'thead',
  'caption',
  'tbody',
  'tr',
  'th',
  'td',
  'pre',
  'iframe',
  'img',
  'span',
];

const ContentWrapper = styled.div`
  p {
    ${({ theme }) => theme.fontStyles.bodyLarger};
  }

  th,
  td {
    white-space: nowrap;
    text-align: left;
    border: 1px solid ${({ theme }) => theme.primary};
  }

  img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  ${props =>
    props.isParagraph &&
    css`
      text-indent: 10%;
    `}

    ${props =>
      props.isDescription &&
      css`
        width: 60%;
        text-align:center;
        margin-left: auto;
        margin-right: auto;
        font-size: 32px;
        font-weight:600;
        letter-spacing: -0.16px;
        color: #6C6C6C;
        ${media.mobile`
          width: 75%;
          p {
            font-size: 0.9rem !important;
          }

        `}
      `}

  ul {
    list-style: none;
    ${({ theme }) => theme.fontStyles.bodyLarge}

    & li::before {
      content: '•';
      color: ${({ theme }) => theme.primary};
      margin-left: -24px;
      width: 24px;
      display: inline-block;
    }
  }
`;

const Content = ({ html, markdown, isParagraph, style, isDescription }) => {
  return (
    <ContentWrapper style={style} isParagraph={isParagraph} isDescription= {isDescription}>
      {html && Parser(sanitizeHtml(html, { allowedTags: ALLOWED_TAGS }))}
      {markdown && <ReactMarkdown>{markdown}</ReactMarkdown>}
    </ContentWrapper>
  );
};

export default Content;
